<template>
  <div
    v-if="isLoading"
    class="pa-7"
  >
    <v-progress-linear
      indeterminate
      color="primary"
    />
    <div class="text-center pa-4">
      Carregando dados...
    </div>
  </div>

  <FormCard
    v-else
    :title="budget.id ? 'Orçamento: #' + budget.id : 'Novo orçamento'"
    :show-save-button="showEditBudgets"
    @save="validate"
    @cancel="() => redirectTo()"
  >
    <v-form
      ref="form"
      class="px-5"
    >
      <v-row dense>
        <v-col cols="12">
          <SectionTitle title="Dados principais" />
          <MainData
            :disabled-fields="showEditBudgets"
            :budget="budget"
            :clients="clients"
            :sellers="sellers"
            :companies="companies"
          />
        </v-col>

        <v-col
          cols="12"
          md="12"
        >
          <SectionTitle :title="totalProducts + ' Produtos'">
            <template slot="action-buttons">
              <v-btn
                v-if="showEditBudgets"
                class="mr-2"
                x-large
                color="primary"
                icon
                @click="() => (viewProducts = !viewProducts)"
              >
                <v-icon>{{ viewProducts ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
              </v-btn>
            </template>
          </SectionTitle>
          <Products
            :show-header-actions="showEditBudgets"
            :budget="budget"
            :view-products="viewProducts"
            :companies="companies"
            @showProducts="viewProducts = !viewProducts"
          />
        </v-col>
        <v-col
          cols="12"
          md="12"
        >
          <SectionTitle title="Dados do orçamento" />
          <BudgetData
            v-if="this.$cookies.get('type') != 'client'"
            :budget="budget ? budget : []"
            :payment-forms="paymentForms"
            :shippings="shippings"
            :deadlines="deadlines"
            :disabled-fields="showEditBudgets"
            class="px-10"
          />
          <BudgetDataClient
            v-if="this.$cookies.get('type') == 'client'"
            :budget="budget ? budget : []"
            :payment-forms="paymentForms"
            :shippings="shippings"
            :deadlines="deadlines"
            class="px-10"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-dialog
      v-model="modal"
      max-width="1000px"
      min-width="80%"
    >
      <FormCard
        :title="temporary.name ? temporary.name : 'Novo Produto Temporário'"
        @cancel="modal = false"
        @save="createTemporary"
      >
        <v-form
          ref="form"
          class="px-5 mt-5"
        >
          <v-row dense>
            <v-col
              cols="12"
              md="12"
            >
              <v-row dense>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="temporary.name"
                    label="Nome"
                    placeholder="Nome do item"
                    outlined
                    rounded
                    dense
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="temporary.value"
                    v-mask="['#,##', '##,##', '###,##', '####,##']"
                    label="Custo"
                    placeholder="Custo do item"
                    outlined
                    rounded
                    dense
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="temporary.vendor_name"
                    label="Fornecedor"
                    auto-grow
                    outlined
                    dense
                    rounded
                    rows="10"
                    row-height="15"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="temporary.vendor_reference"
                    label="Referência do Fornecedor"
                    auto-grow
                    outlined
                    dense
                    rounded
                    rows="10"
                    row-height="15"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-textarea
                    v-model="temporary.description"
                    label="Descrição"
                    auto-grow
                    outlined
                    dense
                    rounded
                    rows="10"
                    row-height="15"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="6"
              md="2"
            >
              <v-row>
                <v-btn
                  class="primary"
                  rounded
                  dense
                  @click="onButtonClick"
                >
                  Adicionar imagem
                  <v-icon
                    class="ml-4"
                    small
                  >
                    fas fa-image
                  </v-icon>
                </v-btn>
                <input
                  id="image_path"
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept="image/*"
                  @change="onFileChanged"
                >
                <v-col
                  rows="12"
                  md="2"
                >
                  <v-avatar
                    class="u-cursor-pointer"
                    width="120px"
                    height="170px"
                    rounded
                    color="grey lighten-4 elevation-2"
                  >
                    <img
                      v-if="
                        temporary.image_path != null || temporaryImage != null
                      "
                      :src="
                        temporaryImage != null
                          ? temporaryImage
                          : $store.state.imagePath + temporary.image_path
                      "
                      class="c-profile-picture"
                      alt="foto de perfil"
                    >

                    <v-icon
                      v-else
                      color="primary"
                    >
                      fas fa-image
                    </v-icon>
                  </v-avatar>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </FormCard>
    </v-dialog>
  </FormCard>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
    FormCard: () => import('@/components/FormCard'),
    SectionTitle: () => import('@/components/SectionTitle'),
    MainData: () => import('./form-sections/MainData'),
    Products: () => import('./form-sections/Products'),
    BudgetData: () => import('./form-sections/BudgetData'),
    BudgetDataClient: () => import('./form-sections-client/BudgetData'),
  },
  props: {
    budget: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    budgetProducts: [],
    viewProducts: true,
    isLoading: true,
    settings: null,
    temporary: {},
    product_temp_code: null,
    temporaryImage: null,
    modal: false,
    shippings: null,
    paymentForms: null,
    deadlines: null,
    clients: null,
    sellers: null,
    companies: null,
    colors: null,
    profile: null,
  }),
  computed: {
    totalProducts() {
      return this.budget.products.length
    },
    ...mapGetters('comercial', ['showEditBudgets']),
  },
  watch: {
    budgetProducts(newvalue) {
      this.budget.products = newvalue
    },
  },
  created() {
    this.getUserProfile()
    this.getSettings()
    this.getAllInfo()
  },
  methods: {
    addProductTemp(res) {
      let new_temp = {
        product_temp_id: res.data.data.id,
        code: res.data.data.code,
        image_path: res.data.data.image_path,
        value: res.data.data.value,
        quantity: [],
      }
      let count = 0
      this.budget.product_temps.map((item) => {
        if (item.product_temp_id == res.data.data.id) {
          count++
        }
      })
      if (count <= 0) {
        this.budget.product_temps.push(new_temp)
      }
    },
    searchTemporary() {
      this.$api
        .post('product_temporary/search', { code: this.product_temp_code })
        .then((res) => {
          this.addProductTemp(res)
          this.modal = false
        })
        .catch((err) => {
          let e = err.response.data.message
          console.log(e)
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops! Falha na busca.',
            caption: e,
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
    createTemporary() {
      const formData = new FormData()
      const temporaryKeys = Object.keys(this.temporary)
      const temporaryValues = Object.values(this.temporary)

      for (let i = 0; i < temporaryKeys.length; i++) {
        if (temporaryValues[i] !== null) {
          formData.append(temporaryKeys[i], temporaryValues[i])
        }
      }
      this.$api
        .post('product_temporary', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          this.addProductTemp(res)
          this.modal = false
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops! Falha ao tentar cadastrar este Produto Temporário',
            caption: 'Verifique se os campos estão preenchidos corretamente.',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
    onButtonClick() {
      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      this.temporary.image_path = e.target.files[0]
      this.temporaryImage = URL.createObjectURL(e.target.files[0])
    },
    clearFileField() {
      this.temporary.image_path = null
    },
    redirectTo() {
      this.$cookies.get('type') != 'client'
        ? this.$router.push({ name: 'budgets' })
        : this.$router.push({ name: 'clients-edit' })
    },
    assignProduct(product) {
      this.budget.products.push(product)
    },
    validate() {
      let isValid = this.$refs.form.validate()
      isValid ? this.$emit('save') : ''
    },
    getSettings() {
      this.$api
        .get('settings')
        .then((res) => {
          this.settings = res.data.data
          this.settingValues()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getAllInfo() {
      this.$api
        .get('budget/get_info')
        .then((res) => {
          this.shippings = res.data.shippings
          this.paymentForms = res.data.paymentForms
          this.clients = res.data.clients
          this.sellers = res.data.sellers
          this.colors = res.data.colors
          this.companies = res.data.companies
          this.initValues()
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    settingValues() {
      if (
        this.budget.validate_budget == '' ||
        this.budget.validate_budget == null
      ) {
        this.budget.validate_budget = this.settings.valid_time_budget
      }
      if (this.budget.deadline == '' || this.budget.deadline == null) {
        this.budget.deadline = this.settings.deadline
      }
      if (this.budget.description == '' || this.budget.description == null) {
        this.budget.description = this.settings.obs_budget
      }
    },
    getUserProfile() {
      this.$api
        .get(`people/${this.$cookies.get('isAuth')}`, {
          headers: {
            Authorization: `Bearer ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.user = res.data.data
          this.$store.commit('setProfile', this.user.profile)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>
